@import "../../styles/variables";

.popup-message {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: #00000073;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-message-box {
    padding: 2rem;
    background-color: $clr--light;
    border-radius: 10px;
    max-width: 100%;
    max-height: 300px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    p {
        text-align: center;
        margin-bottom: 3rem;
        max-width: 300px;
    }

    button {
        padding: 1rem 2rem;
        background-color: $clr--dark;
        border-radius: 5px;
        border: none;
        color: $clr--white;
        transition: 0.3s ease;

        &:hover {
            background-color: $clr--dark-hover;
        }
    }
}

.popup-message-buttons {
    width: 100%;
    text-align: center;
}

.popup-message-buttons-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: center;
    
    
    button {
        flex-grow: 1;

        &:first-child {
            background-color: $clr--black;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}