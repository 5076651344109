@import "./../../../../styles/variables";
#arttour-input-container {
    width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    gap: 1.5rem;
    .add-arttour--column {
        width: 100%;
        display: flex;
        flex-direction: column;
        .position-item {
            width: 100%;
            background: $clr--even-lighter;
            display: flex;
            margin-bottom: 1rem;
            padding: 4px;
            gap: 10px;
            flex-wrap: wrap;
            .pos {
                display: flex;
                flex-direction: column;
            }
            .art {
                flex-grow: 1;
                button {
                    padding: .6rem 1rem;
                }
                img {
                    width: 100%;
                }
            }
            .inputs {
                width: 100%;
                display: flex;
                div {
                    flex-grow: 1;
                    padding: 3px;
                }
            }
        }
    }
    .add-arttour--column-last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .inputfield {
        height: 48px;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $clr--grey-light;
        border-radius: 5px;
        &:disabled {
            color: $clr--black;
        }
    }
    .inputfield--description {
        min-height: 245px;
    }
    textarea {
        resize: none;
    }
    #add-arttour--buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 3rem;
        grid-row-gap: 1rem;
    }
    button {
        font-size: 1rem;
        padding: 1.5rem 1rem;
        background-color: $clr--dark;
        color: $clr--white;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
            background-color: $clr--dark-hover;
        }
        &:first-child {
            background-color: $clr--black;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .addartwork-button {
        margin-left: auto;
        width: 50%;
        padding: 1rem;
        background-color: $clr--black;
        text-align: center;
        &:hover {
            background-color: $clr--black;
            opacity: 0.7;
        }
    }
    #dropdownmenu {
        height: 52px;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $clr--grey-light;
        border-radius: 5px;
        cursor: pointer;
        background-color: $clr--white;
        color: $clr--grey-dark;
        .select-options {
            color: $clr--black;
            cursor: pointer;
        }
        &:disabled {
            color: $clr--black;
        }
    }
}

@media (width < 1024px) {
    #arttour-input-container {
        grid-template-columns: 1fr 1fr;
    }
    .add-arttour--column-last {
        grid-column: 1 / -1;
    }
}

@media (width < 480px) {
    #arttour-input-container {
        grid-template-columns: 1fr;
        .addartwork-button {
            width: 100%;
        }
    }
    #add-arttour--buttons {
        grid-template-columns: 1fr !important;
    }
}