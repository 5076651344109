@import "../../../styles/variables";

#edit-art {
    padding: $padding--page;
    padding-bottom: 2rem;
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    
    h4 {
        margin-bottom: .5rem;
        font-weight: normal;
    }
}

#edit-art--header, #edit-art--body {
    margin-bottom: 2rem;
}

#edit-art--header {
    display: flex;
    justify-content: space-between;

    button {
        transition: 0.3s all ease;
    }

    .edit-art--hide-artwork-button {
        padding:  1rem;
        margin-left: 1rem;
        background-color: $clr--black;
        border: 3px solid $clr--black;
        border-radius: 5px;
        color: $clr--white;
        &:hover {
            background-color: $clr--black-hover;
        }
    }

    .edit-art--hidden-artwork-button {
            padding:  1rem;
            margin-left: 1rem;
            background-color: $clr--light;
            border: 3px solid #ff0000;
            border-radius: 5px;
            color: #ff0000;
            &:hover {
                background-color: #ff0000;
                color: $clr--white;
                border: 3px solid #ff0000;
            }
    }
}



