@import "../../../styles/variables";
#add-arttour {
    padding: $padding--page;
    padding-bottom: 2rem;
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}

#add-arttour--header,
#add-arttour--body {
    margin-bottom: 2rem;
}