@import "../../../../styles/variables";

#user-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100%;

    .inputfield {
        height: 48px;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $clr--grey-light;
        border-radius: 5px;

        &:disabled {
            color: $clr--black;
        }
    }
}

.userinputcontainer--column {
    width: 100%;
    max-width: 500px;

    &:last-child {
        max-width: 400px;
        justify-self: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.userinputcontainer--input-group {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1rem;

    &:nth-child(5) {
        grid-template-columns: 1fr 1fr;
    }
}

#userinputcontainer--buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;

    button {
        font-size: 1rem;
        padding: 1.5rem 1rem;
        background-color: $clr--dark;
        color: $clr--white;
        border-radius: 5px;
        border: none;
        transition: all 0.2s ease;

        &:hover {
            background-color: $clr--dark-hover;
        }

        &:first-child {
            background-color: $clr--black;
            &:hover {
                opacity: 0.7;
            }
        }
    }
}

#userinputcontainer--userrole-selector {
    width: 100%;
    h4 {
        font-weight: bold;
        font-size: 1.5rem;
    }

    select {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        background-color: $clr--light;
        border: $clr--grey-light 1px solid;
        border-radius: 5px;
    }
}
.buttons-error-container {
    width: 100%;
}

@media (width < 800px) {
    #user-input-container {
        grid-template-columns: 1fr;
    }

    .userinputcontainer--column:last-child {
        justify-self: flex-start;
        align-items: flex-start;
        max-width: none;
    }


}

@media (width < 420px) {
    #userinputcontainer--buttons {
        grid-template-columns: 1fr;
    }
}