@import "../../../styles/variables";

#artwork-overview {
    padding: $padding--page;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

#artwork-overview--header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    grid-row-gap: 1.5rem;
    margin-bottom: 1rem;

    .artwork-overview--searchbar {
        align-self: flex-end;
        justify-self: flex-end;
        width: 100%;
        max-width: 275px;
        height: 32px;

        input {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 2px solid $clr--grey-light;
            padding: 1em;
            font-size: 1rem;
        }        
    }
    
    #artwork-overview--buttons {
        display: flex;
        justify-content: space-between;
        grid-column: 1 / -1;

        button {
            max-width: max-content;
            padding: .7rem 1rem;
            transition: 0.2s ease;
        }

        .artwork-overview--add-button {
            background-color: $clr--dark;
            color: $clr--white;
            border: none;
            border-radius: 5px;

            &:hover {
                background-color: $clr--dark-hover;
            }
        }

        .artwork-overview--select-button {
            background-color: $clr--black;
            color: $clr--white;
            border: none;
            border-radius: 5px;

            &:hover {
                background-color: $clr--black-hover;
            }
        }
    }
    
}

#artwork-overview--selecting-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    button {
        background-color: $clr--light;
        border-radius: 5px;
        border: none;
        &:hover {
            background-color: #f1f1f1;
        }

    }
}

#artwork-overview--body {
    margin-bottom: 2rem;
}

#artworkcard-container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

#artwork-overview--page-indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    p {
        font-size: 1.3rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    span {
        font-size: 2rem;
        cursor: pointer;
        //background-color: red;
        height: 40px;
        width: 40px;
        text-align: center;
    }
}



@media (width < 1400px) {
    #artworkcard-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (width < 1024px) {
    #artworkcard-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (width < 800px) {
    .artwork-overview--searchbar {
        grid-column: 1 / -1;
        max-width: none !important;
    }
}

@media (width < 500px) {
    #artwork-overview--buttons {
        flex-direction: column;
        gap: .5rem;

        button {
            width: 100%;
            max-width: none !important;
        }

        #artwork-overview--selecting-menu {
            justify-content: flex-start;
            button {
                width: max-content;
            }
        }
    }

    .artwork-overview--button {
        max-width: none !important;
    }
    
    #artworkcard-container {
        grid-template-columns: 1fr;
    }
}