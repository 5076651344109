@import "../../../../styles/variables";

#form-file-upload {
    height: 350px;
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 5px;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }
  
  #label-file-upload.drag-active {
    background-color: $clr--grey-light;
  }
  
  .upload-button {
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media (width < 1024px) {
    #form-file-upload {
        height: 150px;
    }
  }