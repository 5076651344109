@import "./../../../../styles/variables";
.map-edit-container {
    width: 100%;
    height: 100%;
    z-index: 8;
    .map-edit-content {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        .markerImage {
            width: 100%;
            min-width: 260px;
        }
    }
}