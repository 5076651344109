#edit-user {
    padding: $padding--page;
    padding-bottom: 2rem;
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    
    h4 {
        margin-bottom: .5rem;
        font-weight: normal;
    }
}

#edit-user--header, #edit-user--body {
    margin-bottom: 2rem;
}

