@import "../../styles/variables";

#navbar--vertical-content {
    background: rgb(193,193,193);
    background: linear-gradient(303deg, rgb(212, 212, 212) 0%, rgba(240,240,240,1) 100%);    
    width: 275px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;

    span {
        margin: 2rem 2rem;
        font-size: 1rem;
        display: flex;
        flex-wrap: 1;
        align-items: center;
        max-width: 275px;
        
        a {
            text-decoration: none;
            color: $clr--dark;
        }

        p {
            overflow-wrap: anywhere;
        }

        .navbar--user-icon {
            background: #B3B3B3;
            color: $clr--white;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            flex-shrink: 0;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .navbar--user-name-short {
        display: none;
    }

    .navbar--user-name {
        display: block;
    }

    ul {
        overflow-y: auto;
        font-size: 1.3rem;
        margin-bottom: 2rem;

        li {
            padding: 2rem 2rem;
            transition: 0.2s ease;
        }

        .navlink {
            text-decoration: none;
        }

        .link {
            li{
                background-color: none;
                color: $clr--black;

                &:hover {
                    background-color: #cdcdcd;
                }
            }
        }

        .nav--selected {
            li{
                background-color: $clr--dark;
                color: $clr--white;

                &:hover {
                    background-color: $clr--dark-hover
                }
            }
        }
    }
}

#navbar--horizontal-content{
    display: none;
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: $clr--light;
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.095);
    z-index: 100;

    .navbar--hamburger {
        margin: 0rem 1rem;
        cursor: pointer;

        span {
            transition: 0.3s ease;
        }
    }

    .hamburger-open {
        position: relative;
        
         span {
            position: absolute;
            display: block;
            height: 4px;
            width: 32px;
            background-color: $clr--dark;
            border-radius: 5px;

            &:not(:last-child) {
                margin-bottom: 4px;
            }

            &:nth-child(1){
                transform: rotate(45deg);
            }

            &:nth-child(2){
                opacity: 0;
            }
            
            &:nth-child(3){
                transform: rotate(-45deg);

            }
         }       
    }

    .hamburger-closed {
        span { 
            display: block;
            height: 4px;
            width: 32px;
            background-color: $clr--dark;
            border-radius: 5px;
    
            &:not(:last-child) {
                margin-bottom: 4px;
            }

            &:nth-child(2){
                opacity: 1;
            }
        }
    }
}

@media (height < 500px){
    #navbar--vertical-content {
        span {
            margin: 1rem 1rem;
        }

        ul {
            margin-bottom: 0px;
        }
    }
}

@media (width < 1024px){
    #navbar--vertical-content {
        width: 200px;
        
        ul {
            font-size: 1rem;
        }

        .navbar--user-name {
            display: none;
        }

        .navbar--user-name-short {
            display: block;
        }
    }
}

@media (width < 680px){
    #navbar--vertical-content {
        display: none;
    }

    #navbar--horizontal-content {
        display: flex;
        align-items: center;
    }
}