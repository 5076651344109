.page-title {
  font-size: 1rem;
  font-weight: bold;
}

#login, #login--container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#login {
  min-height: 100%;
  width: 100%;
}

#login--container {
  width: 90%;
  max-width: 350px;
  flex-direction: column;
}
#login--container div {
  width: 100%;
}
#login--container h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
#login--container .input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#login--container .input:not(:last-child) {
  margin-bottom: 1.5rem;
}
#login--container .input input {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #B3B3B3;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}
#login--container .input p {
  align-self: flex-start;
  margin-bottom: 0.5rem;
}
#login--container .error-message {
  align-self: flex-start;
}
#login--container #loginButton {
  width: 100%;
  height: 50px;
  background-color: #de2324;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: 0.2s ease;
}
#login--container #loginButton:hover {
  background-color: #eb4545;
}
#login--container #forgotemail:link, #login--container #forgotemail:visited {
  color: #000000;
  text-decoration: none !important;
}
#login--container #forgotemail:link:hover, #login--container #forgotemail:visited:hover {
  text-decoration: underline;
}
#login--container #forgot-password-title {
  align-self: flex-start;
}
#login--container #password-reset--final-message {
  margin-bottom: 2rem;
}

#navbar--vertical-content {
  background: rgb(193, 193, 193);
  background: linear-gradient(303deg, rgb(212, 212, 212) 0%, rgb(240, 240, 240) 100%);
  width: 275px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
#navbar--vertical-content span {
  margin: 2rem 2rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: 1;
  align-items: center;
  max-width: 275px;
}
#navbar--vertical-content span a {
  text-decoration: none;
  color: #de2324;
}
#navbar--vertical-content span p {
  overflow-wrap: anywhere;
}
#navbar--vertical-content span .navbar--user-icon {
  background: #B3B3B3;
  color: #ffffff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex-shrink: 0;
}
#navbar--vertical-content span .navbar--user-icon img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#navbar--vertical-content .navbar--user-name-short {
  display: none;
}
#navbar--vertical-content .navbar--user-name {
  display: block;
}
#navbar--vertical-content ul {
  overflow-y: auto;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
#navbar--vertical-content ul li {
  padding: 2rem 2rem;
  transition: 0.2s ease;
}
#navbar--vertical-content ul .navlink {
  text-decoration: none;
}
#navbar--vertical-content ul .link li {
  background-color: none;
  color: #000000;
}
#navbar--vertical-content ul .link li:hover {
  background-color: #cdcdcd;
}
#navbar--vertical-content ul .nav--selected li {
  background-color: #de2324;
  color: #ffffff;
}
#navbar--vertical-content ul .nav--selected li:hover {
  background-color: #eb4545;
}

#navbar--horizontal-content {
  display: none;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.095);
  z-index: 100;
}
#navbar--horizontal-content .navbar--hamburger {
  margin: 0rem 1rem;
  cursor: pointer;
}
#navbar--horizontal-content .navbar--hamburger span {
  transition: 0.3s ease;
}
#navbar--horizontal-content .hamburger-open {
  position: relative;
}
#navbar--horizontal-content .hamburger-open span {
  position: absolute;
  display: block;
  height: 4px;
  width: 32px;
  background-color: #de2324;
  border-radius: 5px;
}
#navbar--horizontal-content .hamburger-open span:not(:last-child) {
  margin-bottom: 4px;
}
#navbar--horizontal-content .hamburger-open span:nth-child(1) {
  transform: rotate(45deg);
}
#navbar--horizontal-content .hamburger-open span:nth-child(2) {
  opacity: 0;
}
#navbar--horizontal-content .hamburger-open span:nth-child(3) {
  transform: rotate(-45deg);
}
#navbar--horizontal-content .hamburger-closed span {
  display: block;
  height: 4px;
  width: 32px;
  background-color: #de2324;
  border-radius: 5px;
}
#navbar--horizontal-content .hamburger-closed span:not(:last-child) {
  margin-bottom: 4px;
}
#navbar--horizontal-content .hamburger-closed span:nth-child(2) {
  opacity: 1;
}

@media (height < 500px) {
  #navbar--vertical-content span {
    margin: 1rem 1rem;
  }
  #navbar--vertical-content ul {
    margin-bottom: 0px;
  }
}
@media (width < 1024px) {
  #navbar--vertical-content {
    width: 200px;
  }
  #navbar--vertical-content ul {
    font-size: 1rem;
  }
  #navbar--vertical-content .navbar--user-name {
    display: none;
  }
  #navbar--vertical-content .navbar--user-name-short {
    display: block;
  }
}
@media (width < 680px) {
  #navbar--vertical-content {
    display: none;
  }
  #navbar--horizontal-content {
    display: flex;
    align-items: center;
  }
}
#hamburgernavbar {
  height: calc(100% - 80px);
  width: 100%;
  z-index: 99;
  background-color: #ffffff;
  grid-template-rows: auto 1fr auto;
  position: fixed;
  top: 0;
  margin-top: 80px;
  transition: 0.3s all;
}
#hamburgernavbar span {
  margin: 2rem 2rem;
  font-size: 1rem;
  display: flex;
  flex-wrap: 1;
  align-items: center;
  max-width: 275px;
}
#hamburgernavbar span a {
  text-decoration: none;
  color: #de2324;
}
#hamburgernavbar span p {
  overflow-wrap: anywhere;
}
#hamburgernavbar span .hamburgernavbar--user-icon {
  background: #B3B3B3;
  color: #ffffff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex-shrink: 0;
}
#hamburgernavbar span .hamburgernavbar--user-icon img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#hamburgernavbar ul {
  overflow-y: auto;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
#hamburgernavbar ul li {
  padding: 2rem 2rem;
  transition: 0.2s ease;
}
#hamburgernavbar ul .hamburgernavlink {
  text-decoration: none;
}
#hamburgernavbar ul .hamburgerlink li {
  background-color: none;
  color: #000000;
}
#hamburgernavbar ul .hamburgerlink li:hover {
  background-color: #cdcdcd;
}
#hamburgernavbar ul .hamburgernav--selected li {
  background-color: #de2324;
  color: #ffffff;
}
#hamburgernavbar ul .hamburgernav--selected li:hover {
  background-color: #eb4545;
}

.hamburgernavbar--show {
  display: none;
  left: 0;
}

.hamburgernavbar--hide {
  left: -100%;
}

@media (width < 680px) {
  .hamburgernavbar--show {
    display: grid;
  }
}
#artwork-overview {
  padding: 4rem 3rem;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

#artwork-overview--header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  grid-row-gap: 1.5rem;
  margin-bottom: 1rem;
}
#artwork-overview--header .artwork-overview--searchbar {
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;
  max-width: 275px;
  height: 32px;
}
#artwork-overview--header .artwork-overview--searchbar input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #B3B3B3;
  padding: 1em;
  font-size: 1rem;
}
#artwork-overview--header #artwork-overview--buttons {
  display: flex;
  justify-content: space-between;
  grid-column: 1/-1;
}
#artwork-overview--header #artwork-overview--buttons button {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 0.7rem 1rem;
  transition: 0.2s ease;
}
#artwork-overview--header #artwork-overview--buttons .artwork-overview--add-button {
  background-color: #de2324;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}
#artwork-overview--header #artwork-overview--buttons .artwork-overview--add-button:hover {
  background-color: #eb4545;
}
#artwork-overview--header #artwork-overview--buttons .artwork-overview--select-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}
#artwork-overview--header #artwork-overview--buttons .artwork-overview--select-button:hover {
  background-color: #242424;
}

#artwork-overview--selecting-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
#artwork-overview--selecting-menu button {
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
}
#artwork-overview--selecting-menu button:hover {
  background-color: #f1f1f1;
}

#artwork-overview--body {
  margin-bottom: 2rem;
}

#artworkcard-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

#artwork-overview--page-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
#artwork-overview--page-indicators p {
  font-size: 1.3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
#artwork-overview--page-indicators span {
  font-size: 2rem;
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
}

@media (width < 1400px) {
  #artworkcard-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (width < 1024px) {
  #artworkcard-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (width < 800px) {
  .artwork-overview--searchbar {
    grid-column: 1/-1;
    max-width: none !important;
  }
}
@media (width < 500px) {
  #artwork-overview--buttons {
    flex-direction: column;
    gap: 0.5rem;
  }
  #artwork-overview--buttons button {
    width: 100%;
    max-width: none !important;
  }
  #artwork-overview--buttons #artwork-overview--selecting-menu {
    justify-content: flex-start;
  }
  #artwork-overview--buttons #artwork-overview--selecting-menu button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .artwork-overview--button {
    max-width: none !important;
  }
  #artworkcard-container {
    grid-template-columns: 1fr;
  }
}
.artworkcard {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.171);
  display: flex;
  flex-direction: column;
  border: 3px solid #ffffff;
  cursor: pointer;
  transition: 0.1s ease;
}
.artworkcard:hover {
  transform: scale(0.97);
}

.artworkcard--image {
  width: 100%;
  aspect-ratio: 1/0.7;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

.artworkcard--body {
  padding: 1rem;
  flex-grow: 1;
  display: inherit;
  flex-direction: column;
}
.artworkcard--body h3 {
  margin-bottom: 0.3rem;
}
.artworkcard--body h4 {
  margin-bottom: 1rem;
  font-weight: normal;
  opacity: 0.7;
}
.artworkcard--body p {
  font-size: 0.8rem;
  opacity: 0.7;
  margin-bottom: 1rem;
}
.artworkcard--body .artworkcard--buttons {
  flex-grow: 1;
  display: inherit;
  align-items: flex-end;
}
.artworkcard--body button {
  padding: 0.7rem 1rem;
  background-color: #000000;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.2s ease;
}
.artworkcard--body button:hover {
  opacity: 0.7;
}

.artworkcard--hidden {
  opacity: 0.5;
}
.artworkcard--hidden img {
  opacity: 0.5;
}

.artworkcard--hidden-message {
  position: relative;
}
.artworkcard--hidden-message::after {
  content: "Verborgen";
  font-size: 1.2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #ff0000;
  font-weight: bold;
}

.artworkcard--selected {
  border: 5px solid #32b4ff;
}

#add-art {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
#add-art h4 {
  margin-bottom: 0.5rem;
  font-weight: normal;
}

#add-art--header, #add-art--body {
  margin-bottom: 2rem;
}

#edit-art {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
#edit-art h4 {
  margin-bottom: 0.5rem;
  font-weight: normal;
}

#edit-art--header, #edit-art--body {
  margin-bottom: 2rem;
}

#edit-art--header {
  display: flex;
  justify-content: space-between;
}
#edit-art--header button {
  transition: 0.3s all ease;
}
#edit-art--header .edit-art--hide-artwork-button {
  padding: 1rem;
  margin-left: 1rem;
  background-color: #000000;
  border: 3px solid #000000;
  border-radius: 5px;
  color: #ffffff;
}
#edit-art--header .edit-art--hide-artwork-button:hover {
  background-color: #242424;
}
#edit-art--header .edit-art--hidden-artwork-button {
  padding: 1rem;
  margin-left: 1rem;
  background-color: #ffffff;
  border: 3px solid #ff0000;
  border-radius: 5px;
  color: #ff0000;
}
#edit-art--header .edit-art--hidden-artwork-button:hover {
  background-color: #ff0000;
  color: #ffffff;
  border: 3px solid #ff0000;
}

.multipleSelected--selection-info {
  overflow-y: auto;
}
.multipleSelected--selection-info p {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.multipleSelected--selection-info li {
  margin-left: 2rem;
}

.multiple-art--for-sale-container span {
  margin-right: 0.5rem;
  margin-left: 0px !important;
}
.multiple-art--for-sale-container input {
  margin-right: 0.5rem;
}

#multiple-select-important-text h4 {
  margin-bottom: 0rem;
}
#multiple-select-important-text p {
  margin-bottom: 0.5rem;
}

#art-input-container {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 1.5rem;
  margin-bottom: 2rem;
}
#art-input-container .add-art--column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#art-input-container .add-art--column-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#art-input-container textarea {
  resize: none;
  flex-grow: 1;
  margin-bottom: 0px !important;
}
#art-input-container .checkbox input {
  height: 15px;
  width: 15px;
}
#art-input-container .inputfield--description {
  min-height: 300px;
}
#art-input-container .inputfield--other-information {
  min-height: 200px;
}
#art-input-container .format-container {
  display: flex;
  gap: 0.5rem;
}
#art-input-container .inputfield {
  height: 48px;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}
#art-input-container .inputfield:disabled {
  color: #000000;
}
#art-input-container .greyed-text {
  opacity: 0.6;
  margin-left: 1em;
}
#art-input-container #upload-file {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
#art-input-container #upload-file h3 {
  margin-top: 0.5em;
  margin-bottom: 0;
}
#art-input-container #add-art--buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3rem;
  grid-row-gap: 1rem;
}
#art-input-container #add-art--buttons button {
  font-size: 1rem;
  padding: 1.5rem 1rem;
  background-color: #de2324;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  transition: all 0.2s ease;
}
#art-input-container #add-art--buttons button:hover {
  background-color: #eb4545;
}
#art-input-container #add-art--buttons button:first-child {
  background-color: #000000;
}
#art-input-container #add-art--buttons button:first-child:hover {
  opacity: 0.7;
}

#display-artwork-info {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
#display-artwork-info h3 {
  font-size: 1rem;
}
#display-artwork-info table {
  width: 100%;
  border-collapse: collapse;
}
#display-artwork-info table tr {
  border-bottom: 1px solid #B3B3B3;
}
#display-artwork-info table td {
  padding: 0.5rem;
}
#display-artwork-info table td button {
  text-align: left;
}
#display-artwork-info .display-artwork-info--image {
  height: 100%;
  width: 100%;
  max-height: 400px;
  text-align: center;
}
#display-artwork-info img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#display-artwork-info button {
  background: none;
  border: none;
}
#display-artwork-info button:hover {
  text-decoration: underline;
}

@media (width < 1024px) {
  #art-input-container {
    grid-template-columns: 1fr 1fr;
  }
  .add-art--column-last {
    grid-column: 1/-1;
  }
}
@media (width < 480px) {
  #art-input-container {
    grid-template-columns: 1fr;
  }
  #add-art--buttons {
    grid-template-columns: 1fr !important;
  }
}
#form-file-upload {
  height: 350px;
  width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #B3B3B3;
}

.upload-button {
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

@media (width < 1024px) {
  #form-file-upload {
    height: 150px;
  }
}
.popup-message {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4509803922);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-message-box {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 100%;
  max-height: 300px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}
.popup-message-box p {
  text-align: center;
  margin-bottom: 3rem;
  max-width: 300px;
}
.popup-message-box button {
  padding: 1rem 2rem;
  background-color: #de2324;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  transition: 0.3s ease;
}
.popup-message-box button:hover {
  background-color: #eb4545;
}

.popup-message-buttons {
  width: 100%;
  text-align: center;
}

.popup-message-buttons-container {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.popup-message-buttons-container button {
  flex-grow: 1;
}
.popup-message-buttons-container button:first-child {
  background-color: #000000;
}
.popup-message-buttons-container button:first-child:hover {
  opacity: 0.7;
}

#arttour-overview {
  padding: 4rem 3rem;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

#arttour-overview--buttons > button {
  padding: 0.7rem 1rem;
  background-color: #de2324;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 1.5rem;
  transition: 0.2s ease;
}
#arttour-overview--buttons > button:hover {
  opacity: 0.7;
}

#arttour-overview--table {
  width: 100%;
  margin-top: 1rem;
  border-spacing: 0px;
}
#arttour-overview--table thead > tr > th {
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #000000;
}
#arttour-overview--table tbody > tr {
  background: #f3f3f3;
}
#arttour-overview--table tbody > tr:nth-child(even) {
  background: #fff;
}
#arttour-overview--table tbody > tr td {
  padding: 0.3rem;
}
#arttour-overview--table tbody > tr td:first-child {
  padding-left: 1rem;
}
#arttour-overview--table tbody > tr td:last-child {
  padding-right: 1rem;
}
#arttour-overview--table tbody > tr td button {
  padding: 0.7rem 1rem;
  background-color: #000000;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.2s ease;
}
#arttour-overview--table tbody > tr td button:hover {
  opacity: 0.7;
}
#arttour-overview--table tbody > tr td div.buttonGroup {
  display: flex;
  gap: 0.4rem;
}

#add-arttour {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

#add-arttour--header, #add-arttour--body {
  margin-bottom: 2rem;
}

#arttour-overview-error {
  width: 100%;
  background: #ff0000;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  color: #ffffff;
  padding: 1rem 0.4rem;
}

#arttour-overview--pagination {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
#arttour-overview--pagination button {
  background: #f3f3f3;
  border: 0;
  padding: 0.4rem 1rem;
  transition: 250ms;
}
#arttour-overview--pagination button:hover {
  background: #ffffff;
}
#arttour-overview--pagination button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
#arttour-overview--pagination button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

#arttour-overview--pagination-txt {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 2rem;
}

#user-overview {
  padding: 4rem 3rem;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

#user-overview--header {
  margin-bottom: 1rem;
}
#user-overview--header button {
  margin-top: 1.5rem;
  background-color: #de2324;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 0.7rem 1rem;
}
#user-overview--header button:hover {
  background-color: #eb4545;
}

#user-overview--body {
  margin-bottom: 2rem;
}
#user-overview--body table {
  width: 100%;
  text-align: left;
  border-spacing: 0px;
}
#user-overview--body table tr th {
  padding: 1rem 1rem 1rem 0rem;
}
#user-overview--body table tr td {
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}
#user-overview--body table tr td, #user-overview--body table tr th {
  border-bottom: #B3B3B3 1px solid;
}
#user-overview--body table button {
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: none;
}
#user-overview--body table button:hover {
  background-color: #242424;
}

#add-user {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
#add-user h4 {
  margin-bottom: 0.5rem;
  font-weight: normal;
}

#add-user--header, #add-user--body {
  margin-bottom: 2rem;
}

#edit-user {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
#edit-user h4 {
  margin-bottom: 0.5rem;
  font-weight: normal;
}

#edit-user--header, #edit-user--body {
  margin-bottom: 2rem;
}

#user-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100%;
}
#user-input-container .inputfield {
  height: 48px;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}
#user-input-container .inputfield:disabled {
  color: #000000;
}

.userinputcontainer--column {
  width: 100%;
  max-width: 500px;
}
.userinputcontainer--column:last-child {
  max-width: 400px;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.userinputcontainer--input-group {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}
.userinputcontainer--input-group:nth-child(5) {
  grid-template-columns: 1fr 1fr;
}

#userinputcontainer--buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
#userinputcontainer--buttons button {
  font-size: 1rem;
  padding: 1.5rem 1rem;
  background-color: #de2324;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  transition: all 0.2s ease;
}
#userinputcontainer--buttons button:hover {
  background-color: #eb4545;
}
#userinputcontainer--buttons button:first-child {
  background-color: #000000;
}
#userinputcontainer--buttons button:first-child:hover {
  opacity: 0.7;
}

#userinputcontainer--userrole-selector {
  width: 100%;
}
#userinputcontainer--userrole-selector h4 {
  font-weight: bold;
  font-size: 1.5rem;
}
#userinputcontainer--userrole-selector select {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border: #B3B3B3 1px solid;
  border-radius: 5px;
}

.buttons-error-container {
  width: 100%;
}

@media (width < 800px) {
  #user-input-container {
    grid-template-columns: 1fr;
  }
  .userinputcontainer--column:last-child {
    justify-self: flex-start;
    align-items: flex-start;
    max-width: none;
  }
}
@media (width < 420px) {
  #userinputcontainer--buttons {
    grid-template-columns: 1fr;
  }
}
#popup--container {
  background: #ffffff;
}
#popup--container div.header {
  background: #de2324;
  color: #ffffff;
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  text-align: center;
}
#popup--container div.inputs {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
#popup--container div.inputs .input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#popup--container div.inputs .input:not(:last-child) {
  margin-bottom: 1.5rem;
}
#popup--container div.inputs .input input {
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #B3B3B3;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}
#popup--container div.inputs .input p {
  align-self: flex-start;
  margin-bottom: 0.5rem;
}
#popup--container div.inputs #loginButton {
  width: 100%;
  height: 50px;
  background-color: #de2324;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: 0.2s ease;
}
#popup--container div.inputs #loginButton:hover {
  background-color: #eb4545;
}

#wrapper {
  width: calc(100% - 275px);
  height: 100%;
  float: right;
  background-color: #ffffff;
}

@media (width < 1024px) {
  #wrapper {
    width: calc(100% - 200px);
  }
}
@media (width < 680px) {
  #wrapper {
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 80px;
  }
  .back-link {
    margin-top: 80px;
  }
}
#arttour-overview-error {
  width: 100%;
  background: #ff0000;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  color: #ffffff;
  padding: 1rem 0.4rem;
}

#arttour-input-container {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  gap: 1.5rem;
}
#arttour-input-container .add-arttour--column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#arttour-input-container .add-arttour--column .position-item {
  width: 100%;
  background: #f3f3f3;
  display: flex;
  margin-bottom: 1rem;
  padding: 4px;
  gap: 10px;
  flex-wrap: wrap;
}
#arttour-input-container .add-arttour--column .position-item .pos {
  display: flex;
  flex-direction: column;
}
#arttour-input-container .add-arttour--column .position-item .art {
  flex-grow: 1;
}
#arttour-input-container .add-arttour--column .position-item .art button {
  padding: 0.6rem 1rem;
}
#arttour-input-container .add-arttour--column .position-item .art img {
  width: 100%;
}
#arttour-input-container .add-arttour--column .position-item .inputs {
  width: 100%;
  display: flex;
}
#arttour-input-container .add-arttour--column .position-item .inputs div {
  flex-grow: 1;
  padding: 3px;
}
#arttour-input-container .add-arttour--column-last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#arttour-input-container .inputfield {
  height: 48px;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
}
#arttour-input-container .inputfield:disabled {
  color: #000000;
}
#arttour-input-container .inputfield--description {
  min-height: 245px;
}
#arttour-input-container textarea {
  resize: none;
}
#arttour-input-container #add-arttour--buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 3rem;
  grid-row-gap: 1rem;
}
#arttour-input-container button {
  font-size: 1rem;
  padding: 1.5rem 1rem;
  background-color: #de2324;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}
#arttour-input-container button:hover {
  background-color: #eb4545;
}
#arttour-input-container button:first-child {
  background-color: #000000;
}
#arttour-input-container button:first-child:hover {
  opacity: 0.7;
}
#arttour-input-container .addartwork-button {
  margin-left: auto;
  width: 50%;
  padding: 1rem;
  background-color: #000000;
  text-align: center;
}
#arttour-input-container .addartwork-button:hover {
  background-color: #000000;
  opacity: 0.7;
}
#arttour-input-container #dropdownmenu {
  height: 52px;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #B3B3B3;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  color: #666666;
}
#arttour-input-container #dropdownmenu .select-options {
  color: #000000;
  cursor: pointer;
}
#arttour-input-container #dropdownmenu:disabled {
  color: #000000;
}

@media (width < 1024px) {
  #arttour-input-container {
    grid-template-columns: 1fr 1fr;
  }
  .add-arttour--column-last {
    grid-column: 1/-1;
  }
}
@media (width < 480px) {
  #arttour-input-container {
    grid-template-columns: 1fr;
  }
  #arttour-input-container .addartwork-button {
    width: 100%;
  }
  #add-arttour--buttons {
    grid-template-columns: 1fr !important;
  }
}
.map-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4509803922);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.map-popup-inner {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 100%;
  max-height: 850px;
  max-width: 1750px;
}
.map-popup-inner .map-popup-inner-header {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
}
.map-popup-inner .map-popup-inner-map {
  margin-bottom: 10px;
}
.map-popup-inner .map-popup-inner-map button {
  background-color: blue;
}
.map-popup-inner .map-popup-content {
  width: 100%;
  height: 500px;
}
.map-popup-inner .map-popup-inner-footer {
  width: 100%;
}
.map-popup-inner .map-popup-inner-footer button:last-child {
  margin-left: 1rem;
}

.map-add-artwork {
  display: none;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 100%;
  max-height: 850px;
  max-width: 1750px;
}

.select-art--bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.select-art--bg .select-art--popup {
  width: 100%;
  max-width: 920px;
  background: #f3f3f3;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  height: 100%;
}
.select-art--bg .select-art--popup input {
  width: 100%;
  font-size: 1.3rem;
  padding: 4px;
  margin-bottom: 1rem;
}
.select-art--bg .select-art--popup .select-art--popup-art {
  width: 100%;
  height: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  overflow: auto;
}
.select-art--bg .select-art--popup .select-art--popup-art div {
  cursor: pointer;
  width: calc(30% - 40px);
  min-width: 230px;
  background: rgba(255, 255, 255, 0.75);
  position: relative;
}
.select-art--bg .select-art--popup .select-art--popup-art div img {
  box-sizing: border-box;
  width: 100%;
}
.select-art--bg .select-art--popup .select-art--popup-art div div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  transition: 250ms;
}
.select-art--bg .select-art--popup .select-art--popup-art div:hover div {
  display: flex;
}
.select-art--bg .select-art--popup .select-art--popup-art-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.select-art--bg .select-art--popup .select-art--popup-art-pagination button {
  padding: 0.5rem 1rem !important;
}
.select-art--bg .select-art--popup .select-art--popup-art-pagination button:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.select-art--bg .select-art--popup .select-art--popup-art-pagination button:last-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

#add-arttour {
  padding: 4rem 3rem;
  padding-bottom: 2rem;
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

#add-arttour--header,
#add-arttour--body {
  margin-bottom: 2rem;
}

.buttons-hover {
  z-index: 9;
  display: none;
  position: absolute;
  margin-left: 10px;
}
.buttons-hover.show {
  display: block;
}
.buttons-hover button {
  font-size: 1rem;
  padding: 1.5rem 1rem;
  background-color: #de2324;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 0 0.3rem;
}
.buttons-hover button:hover {
  background-color: #eb4545;
}
.buttons-hover button:first-child {
  background-color: #000000;
}
.buttons-hover button:first-child:hover {
  opacity: 0.7;
}

.map-edit-container {
  width: 100%;
  height: 100%;
  z-index: 8;
}
.map-edit-container .map-edit-content {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.map-edit-container .map-edit-content .markerImage {
  width: 100%;
  min-width: 260px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

.error-message {
  font-size: 1rem;
  color: #ff0000;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.loading-message {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.back-link {
  position: absolute;
  top: 2rem;
  cursor: pointer;
}

.hidden {
  display: none;
}

button {
  cursor: pointer;
}/*# sourceMappingURL=style.css.map */