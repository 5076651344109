@import "./../../../../styles/variables";


#art-input-container {
    width: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    gap: 1.5rem;
    margin-bottom: 2rem;

    .add-art--column {
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    .add-art--column-last {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    textarea { 
        resize: none; 
        flex-grow: 1;
        margin-bottom: 0px !important;
    }

    .checkbox input{
        height: 15px;
        width: 15px;
    }

    .inputfield--description {
        min-height: 300px;
     }

     .inputfield--other-information {
        min-height: 200px;
     }

     .format-container {
        display: flex;
        gap: 0.5rem;
     }

    .inputfield {
        height: 48px;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $clr--grey-light;
        border-radius: 5px;

        &:disabled {
            color: $clr--black;
        }
    }

    .greyed-text {
        opacity: 0.6;
        margin-left: 1em;
    }

    #upload-file {
        font-size: 0.9rem;
        margin-bottom: 1rem;
        h3 {
            margin-top: 0.5em;
            margin-bottom: 0;
        }
    }

    #add-art--buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 3rem;
        grid-row-gap: 1rem;

        button {
            font-size: 1rem;
            padding: 1.5rem 1rem;
            background-color: $clr--dark;
            color: $clr--white;
            border-radius: 5px;
            border: none;
            transition: all 0.2s ease;

            &:hover {
                background-color: $clr--dark-hover;
            }

            &:first-child {
                background-color: $clr--black;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}

#display-artwork-info {
    //flex-grow: 1;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    //background-color: red;

    h3 {
        font-size: 1rem;
    }

    table {
        //background-color: green;
        width: 100%;
        border-collapse: collapse;
        //margin-bottom: 2rem;

        tr {
            //background-color: blue;
            //padding-bottom: 1rem;
            border-bottom: 1px solid $clr--grey-light;
        }

        td {
            padding: 0.5rem;
            button {
                text-align: left;
            }
        }
    }

    .display-artwork-info--image {
        //background-color: red;
        height: 100%;
        width: 100%;
        max-height: 400px;
        text-align: center;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        
        
    }

    button {
        background: none;
        border: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media (width < 1024px) {
    #art-input-container {
        grid-template-columns: 1fr 1fr;
    }
    
    .add-art--column-last {
        grid-column: 1 / -1;
    }
}

@media (width < 480px) {
    #art-input-container {
        grid-template-columns: 1fr;
    }

    #add-art--buttons {
        grid-template-columns: 1fr !important;
    }
}