@import "../../styles/variables";
#arttour-overview--pagination {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    button {
        background: $clr--even-lighter;
        border: 0;
        padding: .4rem 1rem;
        transition: 250ms;
        &:hover {
            background: $clr--light;
        }
        &:first-child {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
        }
        &:last-child {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
        }
    }
}

#arttour-overview--pagination-txt {
    text-align: center;
    padding-top: 10px;
    margin-bottom: 2rem;
}