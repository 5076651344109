@import "./../../../../../styles/variables";
.buttons-hover {
    z-index: 9;
    display: none;
    position: absolute;
    margin-left: 10px;
    &.show {
        display: block;
    }

    button {
        font-size: 1rem;
        padding: 1.5rem 1rem;
        background-color: $clr--dark;
        color: $clr--white;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: all 0.2s ease;
        &:hover {
            background-color: $clr--dark-hover;
        }
        &:first-child {
            background-color: $clr--black;
            &:hover {
                opacity: 0.7;
            }
            margin-right: 1rem;
        }
    }
}