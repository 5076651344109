#wrapper {
    width: calc(100% - 275px);
    height: 100%;
    float: right;
    background-color: $clr--light;
}

@media (width < 1024px) {
    #wrapper {
        width: calc(100% - 200px);
    }
}

@media (width < 680px) {
    #wrapper {
        width: 100%;
        height: calc(100% - 80px);
        margin-top: 80px;
    }
    .back-link {
        margin-top: 80px;
    }
}