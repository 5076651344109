@import "../../styles/variables";

#hamburgernavbar{
    height: calc(100% - 80px);
    width: 100%;
    z-index: 99;
    background-color: $clr--light;
    grid-template-rows: auto 1fr auto;
    position: fixed;
    top: 0;
    margin-top: 80px;
    transition: 0.3s all;

    span {
        margin: 2rem 2rem;
        font-size: 1rem;
        display: flex;
        flex-wrap: 1;
        align-items: center;
        max-width: 275px;
        
        a {
            text-decoration: none;
            color: $clr--dark;
        }

        p {
            overflow-wrap: anywhere;
        }

        .hamburgernavbar--user-icon {
            background: #B3B3B3;
            color: $clr--white;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            flex-shrink: 0;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    ul {
        overflow-y: auto;
        font-size: 1.3rem;
        margin-bottom: 2rem;

        li {
            padding: 2rem 2rem;
            transition: 0.2s ease;
        }

        .hamburgernavlink {
            text-decoration: none;
        }

        .hamburgerlink {
            li{
                background-color: none;
                color: $clr--black;

                &:hover {
                    background-color: #cdcdcd;
                }
            }
        }

        .hamburgernav--selected {
            li{
                background-color: $clr--dark;
                color: $clr--white;

                &:hover {
                    background-color: $clr--dark-hover;
                }
            }
        }
    }
}

.hamburgernavbar--show {
    display: none;
    left: 0;
}

.hamburgernavbar--hide {
    left: -100%;
}

@media (width < 680px){
    .hamburgernavbar--show {
        display: grid;
    }
}