@import "../../../styles/variables";
#arttour-overview {
    padding: $padding--page;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

#arttour-overview--buttons>button {
    padding: 0.7rem 1rem;
    background-color: $clr--dark;
    border: none;
    color: $clr--white;
    border-radius: 5px;
    margin-top: 1.5rem;
    transition: 0.2s ease;
    &:hover {
        opacity: 0.7;
    }
}

#arttour-overview--table {
    width: 100%;
    margin-top: 1rem;
    border-spacing: 0px;
    thead>tr>th {
        text-align: left;
        font-weight: 600;
        border-bottom: 2px solid $clr--black;
    }
    tbody>tr {
        background: $clr--even-lighter;
        &:nth-child(even) {
            background: #fff;
        }
        td {
            &:first-child {
                padding-left: 1rem;
            }
            &:last-child {
                padding-right: 1rem;
            }
            padding: .3rem;
            button {
                padding: 0.7rem 1rem;
                background-color: $clr--black;
                border: none;
                color: $clr--white;
                border-radius: 5px;
                transition: 0.2s ease;
                &:hover {
                    opacity: 0.7;
                }
            }
            div.buttonGroup {
                display: flex;
                gap: .4rem;
            }
        }
    }
}