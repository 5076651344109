//$clr--dark: #151515;
$clr--dark: #de2324;
$clr--black-hover: #242424;
$clr--dark-hover: #eb4545;
$clr--black: #000000;
$clr--white: #ffffff;
$clr--light: #ffffff;
$clr--grey-light: #B3B3B3;
$clr--grey-dark: #666666;
$clr--error-message: #ff0000;
$clr--even-lighter: #f3f3f3;
$padding--page: 4rem 3rem;