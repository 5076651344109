@import "../../../../styles/variables";
.select-art--bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .select-art--popup {
        width: 100%;
        max-width: 920px;
        background: $clr--even-lighter;
        height: max-content;
        height: 100%;
        input {
            width: 100%;
            font-size: 1.3rem;
            padding: 4px;
            margin-bottom: 1rem;
        }
        .select-art--popup-art {
            width: 100%;
            height: 85%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            overflow: auto;
            div {
                cursor: pointer;
                width: calc(30% - 40px);
                min-width: 230px;
                background: rgba(255, 255, 255, 0.75);
                position: relative;
                img {
                    box-sizing: border-box;
                    width: 100%;
                }
                div {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    transition: 250ms;
                }
                &:hover {
                    div {
                        display: flex;
                    }
                }
            }
        }
        .select-art--popup-art-pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 1rem 0;
            button {
                &:first-child {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                &:last-child {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                }
                padding: .5rem 1rem !important;
            }
        }
    }
}