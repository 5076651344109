@import "../../styles/variables";

#popup--container {
    background: $clr--white;

    div.header {
        background: $clr--dark;
        color: $clr--white;
        padding: .4rem 1rem;
        font-size: 1.4rem;
        text-align: center;
    }

    div.inputs {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        .input {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
    
            &:not(:last-child){
                margin-bottom: 1.5rem;
            }
    
            input {
                height: 50px;
                width: 100%;
                border-radius: 5px;
                border: 2px solid $clr--grey-light;
                padding: 1rem 1.5rem;
                font-size: 1rem;
            }
    
            p {
                align-self: flex-start;
                margin-bottom: 0.5rem;
            }
        }

        #loginButton {
            width: 100%;
            height: 50px;
            background-color: $clr--dark;
            border: none;
            color: $clr--white;
            border-radius: 5px;
            margin-bottom: 1rem;
            cursor: pointer;
            transition: 0.2s ease;
    
            &:hover {
                background-color: $clr--dark-hover;
            }
        }
    }
}