@import "../../../styles/variables";

#user-overview {
    padding: $padding--page;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

#user-overview--header {
    margin-bottom: 1rem;
    button {
        margin-top: 1.5rem;
        background-color: $clr--dark;
        color: $clr--white;
        border: none;
        border-radius: 5px;
        padding: .7rem 1rem;

        &:hover {
            background-color: $clr--dark-hover;
        }
    }
}

#user-overview--body { 
    margin-bottom: 2rem;
    table {
        width: 100%;
        text-align: left;
        border-spacing: 0px;
        tr {

            th {
                padding: 1rem 1rem 1rem 0rem;
            }

            td {
                
                padding: .5rem .5rem .5rem 0rem;
            }

            td, th {
                border-bottom: $clr--grey-light 1px solid;
            }
        }

        button {
            background-color: $clr--black;
            color: $clr--white;
            border-radius: 5px;
            padding: .5rem 1rem;
            border: none;

            &:hover {
                background-color: $clr--black-hover;
            }

        }
    }


}
