@import "./../../../../styles/variables";
.map-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000073;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.map-popup-inner {
    padding: 1rem;
    background-color: $clr--light;
    border-radius: 10px;
    max-width: 100%;
    max-height: 850px;
    max-width: 1750px;
    .map-popup-inner-header {
        font-size: 1rem;
        margin-bottom: 10px;
        text-align: center;
    }
    .map-popup-inner-map {
        margin-bottom: 10px;
        button {
            background-color: blue;
        }
    }
    .map-popup-content {
        width: 100%;
        height: 500px;
    }
    .map-popup-inner-footer {
        width: 100%;
        button {
            &:last-child {
                margin-left: 1rem;
            }
        }
    }
}

.map-add-artwork {
    display: none;
    padding: 1rem;
    background-color: $clr--light;
    border-radius: 10px;
    max-width: 100%;
    max-height: 850px;
    max-width: 1750px;
}