@import "./variables";
@import "../components/PageTitle/pagetitle";
@import "../pages/Login/login";
@import "../components/Navbar/navbar";
@import "../components/HamburgerNavbar/hamburgernavbar";
@import "../pages/Art/ArtworkOverview/artworkoverview";
@import "../pages/Art/ArtworkOverview/ArtworkCard/artworkcard";
@import "../pages/Art/AddArt/addart";
@import "../pages/Art/EditArt/editart";
@import "../pages/Art/EditMultipleArt/editmultipleart";
@import "../pages/Art/components/ArtInputContainer/artinputcontainer";
@import "../pages/Art/components/DragDropFile/dragdropfile";
@import "../components/PopupMessage/popupmessage";
@import "../pages/ArtTours/ArtTourOverview/arttouroverview";
@import "../pages/ArtTours/AddArtTour/addarttour";
@import "../pages/ArtTours/ArtTourOverview/ErrorMessage/errormessage";
@import "../pages/ArtTours/ArtTourOverview/TourItem/touritem";
@import "../components/Pagination/pagination";
@import "../pages/Users/UserOverview/useroverview";
@import "../pages/Users/AddUser/adduser";
@import "../pages/Users/EditUser/edituser";
@import "../pages/Users/components/UserInputContainer/userinputcontainer";
@import "../pages/Popup/popup";
@import "../components/Wrapper/wrapper";
@import "../pages/Popup/ErrorMessage/errormessage";
@import "../pages/ArtTours/components/TourInputContainer/tourinputcontainer";
@import "../pages/ArtTours/components/LeafletMap/leafletmap";
@import "../pages/ArtTours/AddArtTour/SelectArt/selectart";
@import "../pages/ArtTours/EditArtTour/editarttour";
@import "../pages/ArtTours/components/TourInputContainer/Buttons/buttons";
@import "../pages/ArtTours/components/LeafletMap/leafletmapedit";
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
}

.error-message {
    font-size: 1rem;
    color: $clr--error-message;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.loading-message {
    font-size: 1rem;
    color: $clr--black;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.inactive {
    pointer-events: none;
    opacity: .5;
}

.back-link {
    position: absolute;
    top: 2rem;
    cursor: pointer;
}

.hidden {
    display: none;
}

button {
    cursor: pointer;
}