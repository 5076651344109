.multipleSelected--selection-info {
    overflow-y: auto;
    p {
        font-weight: bold;
        margin-bottom: .5rem;
    }
    li {
        margin-left: 2rem;
    }
}

.multiple-art--for-sale-container {
    span {
        margin-right: .5rem;
        margin-left: 0px !important;
    }
    input {
        margin-right: .5rem;
    }
}

#multiple-select-important-text {
    h4 {
        margin-bottom: 0rem;
    }

    p {
        margin-bottom: .5rem;
    }
}
