@import "../../styles/variables";

#login, #login--container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#login {
    min-height: 100%;
    width: 100%;
}

#login--container {
    width: 90%;
    max-width: 350px;

    flex-direction: column  ;

    div {
        width: 100%;
    }
    
    h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .input {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child){
            margin-bottom: 1.5rem;
        }

        input {
            height: 50px;
            width: 100%;
            border-radius: 5px;
            border: 2px solid $clr--grey-light;
            padding: 1rem 1.5rem;
            font-size: 1rem;
        }

        p {
            align-self: flex-start;
            margin-bottom: 0.5rem;
        }
    }

    .error-message {
        align-self: flex-start;
    }

    #loginButton {
        width: 100%;
        height: 50px;
        background-color: $clr--dark;
        border: none;
        color: $clr--white;
        border-radius: 5px;
        margin-bottom: 1rem;
        transition: 0.2s ease;

        &:hover {
            background-color: $clr--dark-hover;
        }
    }

    #forgotemail:link, #forgotemail:visited{
        color: $clr--black;
        text-decoration: none !important;
        &:hover {
            text-decoration: underline;
        }
    }

    #forgot-password-title {
        align-self: flex-start;
    }

    #password-reset--final-message {
        margin-bottom: 2rem;
    }
}