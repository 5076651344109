@import "../../../../styles/variables";

.artworkcard {
    //background-color: red;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.171);
    display: flex;
    flex-direction: column;
    border: 3px solid $clr--white;
    cursor: pointer;
    transition: 0.1s ease;
    &:hover {
        transform: scale(0.97);
    }
}

.artworkcard--image {
    //background-color: green;
    width: 100%;
    aspect-ratio: 1 / 0.7;
    object-fit: contain;
    object-position: center top;

}

.artworkcard--body {
    padding: 1rem;
    flex-grow: 1;
    display: inherit;
    flex-direction: column;
    h3 {
        margin-bottom: 0.3rem;
    }
    h4 {
        margin-bottom: 1rem;
        font-weight: normal;
        opacity: 0.7;
    }
    p {
        font-size: 0.8rem;
        opacity: 0.7;
        margin-bottom: 1rem;
    }
    .artworkcard--buttons {
        flex-grow: 1;
        display: inherit;
        align-items: flex-end;
    }
    button {
        padding: 0.7rem 1rem;
        background-color: $clr--black;
        border: none;
        color: $clr--white;
        border-radius: 5px;
        transition: 0.2s ease;
        &:hover {
            opacity: 0.7;
        }
    }
}

.artworkcard--hidden {
    //box-shadow:inset 0px 0px 0px 5px #f00;
    opacity: 0.5;
    img {
        opacity: 0.5;
    }
}

.artworkcard--hidden-message {
    position: relative;
    &::after {
        content: "Verborgen";
        font-size: 1.2rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
        color: #ff0000;
        font-weight: bold;
    }
}

.artworkcard--selected {
    border: 5px solid #32b4ff;

}